import React from 'react';
import {CircularProgress} from "@mui/material";

export default function FullScreenLoading({width}) {

    const containerStyle = {
        alignItems: 'center',
        display: 'flex',
        height: '100vh',
        justifyContent: 'center'
    }

    const style = {
        position: 'relative',
        textAlign: 'center',
    }

    return (
        <div style={containerStyle}>
            <div style={style}>
                <CircularProgress/>
            </div>
        </div>
    );
}
