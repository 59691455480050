import {Route, Routes} from "react-router-dom";
import {Container} from "@mui/material";
import Viewer from "./viewer/Viewer";
import MinterStepper from "./minting/MinterStepper";
import MinterPage from "./minting/MinterPage";

function App() {

    return (
        <Container>
            <Routes>
                <Route path="/" element={<MinterPage/>}/>
                <Route path="/view/:tokenId" element={<Viewer/>}/>
            </Routes>
        </Container>
    );
}

export default App;
