import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {useNavigate} from "react-router-dom";
import Web3Service from "../../services/Web3Service";
import ApiService from "../../services/ApiService";
import {Alert, AlertTitle, Grid, TextField} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import FullScreenLoading from "../util/FullScreenLoading";

const web3Service = Web3Service()
const apiService = ApiService()
const MAX_LENGTH_NAME = 25

export default function MinterStepper() {

    const navigate = useNavigate();

    const [activeStep, setActiveStep] = React.useState(0);
    const [name, setName] = useState("")
    const [signature, setSignature] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingPage, setIsLoadingPage] = useState(true)
    const [errorMessage, setErrorMessage] = useState("")
    const [hint, setHint] = useState("")
    const [mintingError, setMintingError] = useState("")
    const [isMinted, setIsMinted] = useState(false)

    const handleNext = () => {
        setHint("")
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setHint("")
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    // const handleReset = () => {
    //     setActiveStep(0);
    // };

    useEffect(() => {
        async function initialLoad() {
            const isConnected = await web3Service.isConnected()
            if (isConnected) {
                setActiveStep(1)
                setIsLoadingPage(false)
            } else {
                setActiveStep(0)
                setIsLoadingPage(false)
            }
        }
        initialLoad()

        // web3Service.init()
    }, [])

    async function connectWallet() {
        setIsLoading(true)
        try {
            const message = await web3Service.init()
            if (message) {
                setHint(message)
            } else {
                await forwardIfTheyAlreadyHaveBadge()
                setErrorMessage("")
                handleNext()
            }
        } catch (e) {
            setErrorMessage(e.message)
        }
        setIsLoading(false)
    }

    // async function switchToCorrectChain() {
    //     setIsLoading(true)
    //     try {
    //         const result = await web3Service.switchToCorrectChain()
    //         setErrorMessage("")
    //
    //         if (result) {
    //             setHint(result)
    //         } else {
    //             handleNext()
    //         }
    //     } catch (e) {
    //         setErrorMessage(e.message)
    //     }
    //     setIsLoading(false)
    // }

    async function forwardIfTheyAlreadyHaveBadge() {
        // if (web3Service.isOnTheCorrectChain()) {
        //     const tokenIds = await web3Service.fetchTokenIds(await web3Service.getCurrentAccount())
        //     if (tokenIds.length > 0) {
        //         navigate("/view/" + tokenIds[tokenIds.length - 1])
        //     }
        // }
    }

    async function signName() {
        const signature = await web3Service.sign(name)
        setSignature(signature)
        handleNext()
    }

    async function sendMintRequest() {
        setIsLoading(true)
        setMintingError("")
        // const signature = await web3Service.sign(name)

        try {
            const mintData = await apiService.mintToken(name, signature, await web3Service.getCurrentAccount())
            setIsMinted(true)
            let events = await apiService.fetchMintEvent((mintData.receipt.blockNumber - 1), await web3Service.getCurrentAccount())
            console.log(events)

            let attempts = 0
            while (!events.length && attempts < 35) {
                await new Promise(resolve => {
                    setTimeout(async () => {
                        //get the tokenId from the events
                        try {
                            events = await apiService.fetchMintEvent((mintData.receipt.blockNumber - 1), await web3Service.getCurrentAccount())
                        } catch (e) {
                            console.error(e)
                        }
                        attempts++
                        resolve()
                    }, 3000)
                })
            }

            const tokenId = events[0].returnValues["tokenId"]
            navigate("/view/" + tokenId)
            setIsLoading(false)
        } catch (e) {
            console.error(e)
            setIsLoading(false)
            setMintingError("Unfortunately there was an issue and we could not mint your badge. Please try again in 1 minute.")
        }
    }

    if (isLoadingPage) {
        return <FullScreenLoading/>
    }

    function renderError() {
        if (errorMessage) {
            return (
                <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {errorMessage}
                </Alert>
            )
        }
        return ""
    }

    return (
        <Grid container alignItems={"center"} justifyContent={"center"}>
            <Grid item xs={12}>
                <Grid container alignItems={"center"} justifyContent={"center"} style={{paddingTop: 56}}>
                    {/*<Grid item xs={12} style={{marginBottom: 32}}>*/}
                    {/*    <Alert severity="info">*/}
                    {/*        <AlertTitle>Mint your NFT!</AlertTitle>*/}
                    {/*        Below we walk you through the steps to successfully mint yourself your completion NFT*/}
                    {/*    </Alert>*/}
                    {/*</Grid>*/}
                    <Grid item xs={12}>
                        {renderError()}
                        <Box sx={{ maxWidth: 400, textAlign: "left" }}>
                            <Stepper activeStep={activeStep} orientation="vertical">
                                <Step>
                                    <StepLabel>
                                        <strong>Connect your MetaMask</strong>
                                    </StepLabel>
                                    <StepContent>
                                        <Typography>In this step we connect your metamask to our website so you can sign your certificate.</Typography>
                                        <Typography><strong>{hint}</strong></Typography>
                                        <Box sx={{ mb: 2 }}>
                                            <div>
                                                <LoadingButton
                                                    loading={isLoading}
                                                    variant="contained"
                                                    onClick={connectWallet}
                                                    sx={{ mt: 1, mr: 1 }}
                                                >
                                                    Connect
                                                </LoadingButton>
                                                {/*<Button*/}
                                                {/*    // disabled={index === 0}*/}
                                                {/*    onClick={handleBack}*/}
                                                {/*    sx={{ mt: 1, mr: 1 }}*/}
                                                {/*>*/}
                                                {/*    Back*/}
                                                {/*</Button>*/}
                                            </div>
                                        </Box>
                                    </StepContent>
                                </Step>
                                {/*<Step>*/}
                                {/*    <StepLabel>*/}
                                {/*        <strong>Add the Polygon network to your MetaMask</strong>*/}
                                {/*    </StepLabel>*/}
                                {/*    <StepContent>*/}
                                {/*        <Typography>In this step will connect your MetaMask to the Polygon network. First you will be asked to add the Polygon network to your MetaMask then you will be prompted to switch to the Polygon network.</Typography>*/}
                                {/*        <Typography><strong>{hint}</strong></Typography>*/}
                                {/*        <Box sx={{ mb: 2 }}>*/}
                                {/*            <div>*/}
                                {/*                <LoadingButton*/}
                                {/*                    loading={isLoading}*/}
                                {/*                    variant="contained"*/}
                                {/*                    onClick={switchToCorrectChain}*/}
                                {/*                    sx={{ mt: 1, mr: 1 }}*/}
                                {/*                >*/}
                                {/*                    Connect*/}
                                {/*                </LoadingButton>*/}
                                {/*            </div>*/}
                                {/*        </Box>*/}
                                {/*    </StepContent>*/}
                                {/*</Step>*/}
                                <Step>
                                    <StepLabel>
                                        <strong>Sign your name</strong>
                                    </StepLabel>
                                    <StepContent>
                                        <Typography>Enter your name as you want it to appear on your NFT.</Typography>
                                        <TextField id="outlined-basic" label="Your Name" variant="outlined" value={name}
                                                   style={{marginTop: 24}} inputProps={{ maxLength: MAX_LENGTH_NAME }}
                                                   onChange={e => setName(e.target.value)}
                                                   helperText={name.length + ' of ' + MAX_LENGTH_NAME}
                                        />
                                        <Box sx={{ mb: 2 }}>
                                            <div>
                                                <Button
                                                    disabled={!name || !name.trim()}
                                                    variant="contained"
                                                    onClick={signName}
                                                    sx={{ mt: 1, mr: 1 }}
                                                >
                                                    Next
                                                </Button>
                                            </div>
                                        </Box>
                                    </StepContent>
                                </Step>

                                <Step>
                                    <StepLabel>
                                        <strong>Mint your NFT</strong>
                                    </StepLabel>
                                    <StepContent>
                                        <Typography>This may take a couple of minutes please be patient and please keep this browser window open!</Typography>
                                        <Typography color={"red"}><strong>{mintingError}</strong></Typography>
                                        <Box sx={{ mb: 2 }}>
                                            <div>
                                                <LoadingButton
                                                    variant="contained"
                                                    loading={isLoading}
                                                    onClick={sendMintRequest}
                                                    sx={{ mt: 1, mr: 1 }}
                                                >
                                                    Create
                                                </LoadingButton>
                                                <Button
                                                    onClick={handleBack}
                                                    sx={{ mt: 1, mr: 1 }}
                                                >
                                                    Back
                                                </Button>
                                            </div>
                                        </Box>
                                        {isLoading && !isMinted ? <Typography><small>Minting your token...</small></Typography> : ''}
                                        {isLoading && isMinted ? <Typography><small>Successfully minted, retrieving metadata...</small></Typography> : ''}
                                    </StepContent>
                                </Step>
                            </Stepper>
                            {/*{activeStep === steps.length && (*/}
                            {/*    <Paper square elevation={0} sx={{ p: 3 }}>*/}
                            {/*        <Typography>All steps completed - you&apos;re finished</Typography>*/}
                            {/*        <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>*/}
                            {/*            Reset*/}
                            {/*        </Button>*/}
                            {/*    </Paper>*/}
                            {/*)}*/}
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
