import Web3 from "web3";
// import Contract from 'web3-eth-contract'
// import Web3EduverseIntroductionToWeb3 from "../contracts/Web3EduverseIntroductionToWeb3.json"

const CONTRACT_ADDRESS = process.env["REACT_APP_CONTRACT_ADDRESS"]
// const CHAIN_ID = process.env["REACT_APP_CHAIN_ID"]
// const CHAIN_CONFIG = JSON.parse(process.env["REACT_APP_CHAIN_CONFIG"])

const ERROR_CODE_REQUEST_PENDING = -32002
const ERROR_CODE_USER_REJECTED = 4001
// const ERROR_CODE_MISSING_NETWORK = 4902

const Web3Service = () => {

    const web3 = new Web3(Web3.givenProvider);
    // Contract.setProvider(Web3.givenProvider);
    // const contract = new Contract(Web3EduverseIntroductionToWeb3, CONTRACT_ADDRESS);

    async function init() {

        try {
            await window.ethereum.enable();
        } catch (e) {
            if(e.code === ERROR_CODE_REQUEST_PENDING) {
                return "The request to is already pending in your MetaMask. Check your MetaMask!"
            } else if (e.code === ERROR_CODE_USER_REJECTED) {
                return "You must accept the MetaMask request to proceed!"
            } else {
                throw e
            }
        }

        if (window.ethereum) {
            try {
                await window.ethereum.request({method: 'eth_requestAccounts'});
            } catch (e) {
                if(e.code === ERROR_CODE_REQUEST_PENDING) {
                    return "The request to is already pending in your MetaMask. Check your MetaMask!"
                } else if (e.code === ERROR_CODE_USER_REJECTED) {
                    return "You must accept the MetaMask request to proceed!"
                } else {
                    throw e
                }
            }
            window.web3 = new Web3(window.ethereum);
            return null
        }

        return null
    }

    async function isConnected() {
        const accounts = await web3.eth.getAccounts()
        return accounts.length > 0
    }

    async function getCurrentAccount() {
        if (!window.ethereum) {
            await init()
        }
        const accounts = await web3.eth.getAccounts()
        if (accounts) {
            return accounts[0]
        }
    }

    async function sign(value) {
        if (!web3) {
            await init()
        }
        const account = await getCurrentAccount()
        return await web3.eth.personal.sign(value, account);
    }

    // async function switchToCorrectChain() {
    //     try {
    //         await switchEthereumChain()
    //     } catch (e) {
    //         console.log(e)
    //         if (e.code === ERROR_CODE_MISSING_NETWORK) {
    //             console.log("Please add the Polygon network to MetaMask")
    //             return await addEthereumChain()
    //         } else if(e.code === ERROR_CODE_REQUEST_PENDING) {
    //             return "The request to is already pending in your MetaMask. Check your MetaMask!"
    //         } else if (e.code === ERROR_CODE_USER_REJECTED) {
    //             return "You must accept the MetaMask request to proceed!"
    //         } else {
    //             throw e
    //         }
    //     }
    // }

    // function isOnTheCorrectChain() {
    //     return CHAIN_ID === getChainId()
    // }
    // function getChainId() {
    //     return window.ethereum.chainId
    // }
    //
    // async function switchEthereumChain() {
    //     await window.ethereum.request({
    //         method: 'wallet_switchEthereumChain',
    //         params: [{ chainId: CHAIN_ID}],
    //     });
    //     console.log("You have switched to the right network")
    // }
    //
    // async function addEthereumChain() {
    //     try {
    //         await window.ethereum.request({
    //             method: 'wallet_addEthereumChain',
    //             params: [CHAIN_CONFIG]
    //         });
    //         return null
    //     } catch (e) {
    //         if(e.code === ERROR_CODE_REQUEST_PENDING) {
    //             return "The request to is already pending in your MetaMask. Check your MetaMask!"
    //         } else if (e.code === ERROR_CODE_USER_REJECTED) {
    //             return "You must accept the MetaMask request to proceed!"
    //         }  else {
    //             throw e
    //         }
    //     }
    // }
    //
    // async function getMintEvent(fromBlock) {
    //     const currentAccount = await getCurrentAccount()
    //     console.log("About to query past events")
    //     console.log("currentAccount", currentAccount)
    //     console.log("fromBlock", fromBlock - 1)
    //
    //     return contract.getPastEvents("Transfer", {
    //         fromBlock: (fromBlock - 1),
    //         toBlock: 'latest',
    //         filter: {from: '0x0000000000000000000000000000000000000000', to: currentAccount}
    //     }).then(events => {
    //         console.log('events')
    //         console.log(events)
    //         return events
    //     }).catch(error => {
    //         console.error(error)
    //     })
    // }

    // async function fetchTokenUri(tokenId) {
    //     try {
    //         return await contract.methods.tokenURI(tokenId).call()
    //     } catch (e) {
    //         console.error(e)
    //     }
    // }

    // async function balanceOf(account) {
    //     return await contract.methods.balanceOf(account).call()
    // }
    //
    // async function tokenOfOwnerByIndex(account, index) {
    //     return await contract.methods.tokenOfOwnerByIndex(account, index).call()
    // }

    // async function fetchTokenIds(account) {
    //     const balance = await balanceOf(account)
    //     const tokenIds = []
    //     for (let i = 0; i < balance; i++) {
    //         tokenIds.push(await tokenOfOwnerByIndex(account, i))
    //     }
    //     console.log('tokenIds', tokenIds)
    //     return tokenIds
    // }

    return {
        init,
        isConnected,
        getCurrentAccount,
        sign,
        CONTRACT_ADDRESS
        // getChainId,
        // isOnTheCorrectChain,
        // fetchTokenIds,
        // fetchTokenUri,
        // getMintEvent,
        // switchToCorrectChain,
    }
}

export default Web3Service
