import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_BASE_URL

const ApiService = () => {

    async function mintToken(name, signature, to) {
        const axiosResponse = await axios.post(BASE_URL + "/intro", {
            name: name,
            signature: signature,
            to: to
        })

        return axiosResponse.data
    }

    async function fetchTokenUri(tokenId) {
        const axiosResponse = await axios.get(BASE_URL + "/token/" + tokenId)
        return axiosResponse.data
    }

    async function fetchMintEvent(fromBlock, account) {
        const axiosResponse = await axios.get(BASE_URL + "/event?fromBlock=" + fromBlock + "&account=" + account)
        return axiosResponse.data
    }

    return {
        mintToken,
        fetchTokenUri,
        fetchMintEvent
    }
}

export default ApiService
